// form css
.form-container {
  min-height: 100vh;
  padding-top: 200px;
  background: linear-gradient(to bottom, #fff, #fff 10%, #13a1ff, #13a1ff 80%);
  .form-header {
    color: #1d235c;
    padding: 25px 0;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
  }
  .form-sub-header {
    margin-bottom: 25px;
    font-size: 18px;
    text-align: center;
    color: #fff;
  }
  .form-sub-text {
    margin-bottom: 25px;
    font-size: 18px;
    text-align: center;
    color: #fff;
    opacity: 0.59;
  }
  .submit-button {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0;
    margin: 10px 0 0 0;
    border-radius: 6px;
    background: #1d235c;
    text-transform: uppercase;
    &:hover {
      background: #1d235c;
    }
  }
  .vetrains-logo {
    width: 170px;
  }
  .forgot-password-link {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding-top: 15px;
    text-align: center;
    text-decoration: underline;
    span {
      cursor: pointer;
    }
  }
  .signup-link {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding-top: 15px;
    text-align: center;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
  .disclaimer-button {
    color: #169df9;
    background: #fff;
  }
  .eos-date-container {
    position: relative;
    padding: 0;
    margin: 0;
  }
  .calendar-container {
    margin-bottom: 1rem;
    .react-calendar {
      width: 60%;
    }
  }
  .reset-password {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
  }
  .bold-underline {
    text-decoration: underline;
  }
  .not-found-title {
    font-size: 80px;
    font-weight: 600;
    color: #fff;
  }
  .remeber-password-link {
    padding-top: 15px;
    text-align: center;
    color: #fff;
    font-size: 16px;

    a {
      color: #fff !important;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  .link-color {
    color: #fff !important;
    font-weight: 700;
    text-decoration: underline;
  }
  .not-found-logo {
    .path2 {
      font-size: 70px;
      margin-top: 25px;
      &::before {
        margin: 0;
      }
    }
    &:after {
      position: absolute;
      content: "";
      width: 25%;
      height: 100%;
      top: -30px;
      left: 20%;
      border-bottom: 10px solid #fff;
      box-sizing: border-box;
      @media screen and (max-width: 770px) {
        left: 16%;
      }
      @media screen and (max-width: 600px) {
        left: 10%;
      }
    }
    &:before {
      position: absolute;
      content: "";
      width: 25%;
      height: 100%;
      top: 30px;
      left: 55%;
      border-top: 10px solid #fff;
      box-sizing: border-box;
      @media screen and (max-width: 770px) {
        left: 58%;
      }
      @media screen and (max-width: 600px) {
        left: 65%;
      }
    }
  }
  .not-found-sub-title {
    top: -30px;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
  }
  .password-container {
    position: relative;
    .eye-icon {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .location-fields {
    border-radius: 5px;
    border: 1px solid #1f96ed;
  }

  @media screen and (max-width: 1600px) {
    padding-top: 70px;
  }
  @media screen and (max-height: 768px) {
    height: auto;
    padding-top: 25px;
  }
  @media screen and (max-width: 600px) {
    .calendar-container {
      .react-calendar {
        width: auto;
      }
    }
  }
}
.form-group .d-block input {
  color: #000;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
#login-form {
  .error-msg {
    text-align: center;
    padding-top: 20px;
    font-size: 18px;
    color: #951010;
  }
}

.label-name {
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px;
}

.success-msg {
  padding: 5px;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  background-color: #56b891;
}

.submit-button,
.cancel-button {
  margin: 0 10px;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 40px;
  text-transform: uppercase;
  border: 1px solid #1f96ed;
}
.cancel-button {
  color: #1f96ed;
  background: #fff;
}
.submit-button {
  color: #fff;
  background: #1f96ed;
}

.pointer-cursor {
  cursor: pointer;
}

//icomoon styles
.icomoon-icon {
  font-size: 30px;
}
.icomoon-success {
  font-size: 70px;
  .path2 {
    &::before {
      margin: 0 !important;
    }
  }
}

.logos-container {
  img {
    width: 194px;
    height: 55px;
    cursor: pointer;
    margin-right: 3px;

    &:last-child {
      margin-right: 0px;
    }

    @media screen and (max-width: 330px) {
      width: 49px;
      height: 49px;
    }
  }
}

.dashboard-body {
  padding-bottom: 130px;
  overflow-y: auto;
}

.static-page-component {
  padding-top: 30px;
  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .icomoon-resource {
      color: #1369b4;
      font-size: 22px;
      margin-right: 12px;
    }
    .title {
      color: #1369b4;
      margin-bottom: 0;
    }
  }
  .weblinks {
    padding: 0;
    list-style: none;
    .links {
      display: flex;
      font-size: 18px;
      align-items: center;
      padding-bottom: 15px;
      justify-content: flex-start;
      .icomoon-list-icon {
        font-size: 16px;
        margin: 0 15px 0 8px;
      }
      a {
        word-break: break-all;
        text-transform: capitalize;
        text-decoration: underline;
      }
    }
  }
  .space-wrapper {
    padding: 0 100px;
  }
  @media screen and (max-width: 1024px) {
    .space-wrapper {
      padding: 0 70px;
    }
  }
  @media screen and (max-width: 992px) {
    .space-wrapper {
      padding: 0 30px;
    }
  }
}
.static-page-component__padding-bottom {
  padding-bottom: 200px;
}
.simple-form input {
  border: 1px solid #1f96ed !important;
}

.simple-form-container {
  padding: 20px 0 40px 0;
}

.verify-email-container {
  display: flex;
  align-items: center;
  .form-group {
    width: 100%;
  }
  .verify-email {
    border: 0;
    width: 40%;
    outline: 0;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    padding-left: 10px;
    background: transparent;
    margin-bottom: 1rem;
    text-decoration: underline;
    font-size: 14px;
    &:disabled {
      cursor: no-drop;
    }
  }
  .check-img {
    width: 30px;
    height: 30px;
    margin-bottom: 1rem;
    margin-left: 10px;
  }
}

@media screen and (min-width:100px) and (max-width:400px) {
  .verify-email-container {
  
    .verify-email {
      width: 18%;
      padding-left: 8px;
    }
  }
}

@media screen and (min-width:600px) {
  .verify-email-container {

    .verify-email {
      width: 25%;
      padding-right: 0px;
    }
  }
}
