@import "../src/shared/style/mixins.scss";

// .row{
//     margin: 0;
// }
body {
  // @include prefix(height, calc(100% - 50px));
  font-family: "Roboto" !important;
}

#root {
  @include prefix(height, calc(100% - 100px));
  // height: 99vh;
}

.error-msg {
  color: red;
}
.font-size-18 {
  font-size: 18px;
}
.cursor-na {
  cursor: not-allowed !important;
}