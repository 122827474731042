@font-face {
  font-family: "icomoon";
  src: url("./icomoon.eot?z3t1pq");
  src: url("./icomoon.eot?z3t1pq#iefix") format("embedded-opentype"),
    url("./icomoon.ttf?z3t1pq") format("truetype"),
    url("./icomoon.woff?z3t1pq") format("woff"),
    url("./icomoon.svg?z3t1pq#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-statement-of-benifits-white:before {
  content: "\e902";
  color: #fff;
}
.icon-pencil:before {
  content: "\e903";
}
.icon-profile-upload:before {
  content: "\e904";
  color: #fff;
}
.icon-arrow-left-circle:before {
  content: "\e905";
}
.icon-arrow-right-circle:before {
  content: "\e906";
}
.icon-circle-with-plus:before {
  content: "\e910";
}
.icon-Education:before {
  content: "\e923";
}
.icon-career:before {
  content: "\e924";
}
.icon-Health:before {
  content: "\e925";
}
.icon-Statement--benifits-color:before {
  content: "\e927";
  color: #1369b4;
}
.icon-left-arrow:before {
  content: "\e93a";
  color: #1369b4;
}
.icon-uniE93B:before {
  content: "\e93b";
}
.icon-uniE93C:before {
  content: "\e93c";
}
.icon-career-financial-resources:before {
  content: "\e940";
  color: #fff;
}
.icon-contact:before {
  content: "\e946";
  color: #159efa;
}
.icon-down-arrow:before {
  content: "\e948";
  color: #1f96ed;
}
.icon-draft-arrow:before {
  content: "\e949";
  color: #fff;
}
.icon-edit-icon:before {
  content: "\e94a";
  color: #217feb;
}
.icon-education-resources:before {
  content: "\e94b";
  color: #fff;
}
.icon-health-wellbeing:before {
  content: "\e94f";
  color: #fff;
}
.icon-home1:before {
  content: "\e950";
  color: #159efa;
}
.icon-icon-calendar:before {
  content: "\e952";
  color: #bfbfbf;
}
.icon-icon-save:before {
  content: "\e953";
  color: #fff;
}
.icon-logout:before {
  content: "\e954";
  color: #159efa;
}
.icon-uniE955:before {
  content: "\e955";
}
.icon-uniE956:before {
  content: "\e956";
}
.icon-profile:before {
  content: "\e957";
  color: #159efa;
}
.icon-rotation-lock:before {
  content: "\e958";
  color: #199bf5;
}
.icon-status-completed:before {
  content: "\e959";
  color: #fff;
}
.icon-right-arrow:before {
  content: "\e95a";
  color: #fff;
}
.icon-timeline:before {
  content: "\e95d";
  color: #fff;
}
.icon-sort:before {
  content: "\e907";
}
.icon-sort-asc:before {
  content: "\e900";
}
.icon-sort-desc:before {
  content: "\e901";
}
.icon-success .path2:before {
  content: "\e959";
  color: rgb(0, 214, 149);
}
.icon-notFoundLogo .path2:before {
  content: "\e956";
  color: rgb(255, 0, 0);
}
.icon-news:before {
  content: "\e908";
  color: #fff;
}
.icon-clear:before {
  content: "\e909";
}

.icon-bin2:before {
  content: "\e9ad";
}

.icon-bin:before {
  content: "\e9ac";
}

.icon-medal:before {
  content: "\e90a";
  color: #fff;
}

.icon-arrow-left2:before {
  content: "\ea40";
}

.icon-arrow-left:before {
  content: "\ea38";
}